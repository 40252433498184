


















import { Component, Vue } from "vue-property-decorator";
import MyForm from "@/components/MyForm.vue";
import Mixin from "@/js/mixins";

@Component({ components: { MyForm }, mixins: [Mixin] })
export default class BasicInfo extends Vue {
  public user_id = "";
  public form_list: MyForm.formList[] = [];
  public id = '';

  // form组件的回调（值改变时获取数据）
  formChange(form_list: MyForm.formList[]) {
    this.form_list = form_list;
  }
  // 提交表单
  submit() {
    let form: any = this.$refs.form;
    console.log(this.form_list)
    if (form.ValidateAll()) {
      
      this.submitRequest(this.$api.getFormData([this.form_list]));
    }
  }
  // 提交表单（请求接口）
  submitRequest(data: Dictionary<string>) {
    let _this = this;
    this.$api.request({
      url: "user/users/up-user-address",
      data:{
        realname:this.form_list[0].value,
        mobile:this.form_list[1].value,
        user_id:this.$route.params.user_id,
        id:this.$route.params.id
      },
      success(res) {
        // _this.$api.href(`/shop/address?user_id=${_this.$route.params.user_id}`);
        // _this.$api.goBack()
        _this.$toast('修改成功！')
        setTimeout(time=>{
          _this.$api.goBack()
        },1000)
      }
    });
  }

  init() {
    this.user_id = String(this.$route.query.user_id || "");
    this.id = String(this.$route.query.id || "");
    console.log(this.user_id,'这是userid')
    this.form_list = [
      {
        name: "name",
        value: this.$route.params.name,
        label: "姓名",
        type: "input",
        placeholder: '请输入收货人姓名',
        required: true
      },
      {
        name: "mobile",
        value: this.$route.params.mobile,
        label: "联系方式",
        type: "input",
        placeholder: '请输入联系方式',
        required: true
      },
      

    ];
    this.$api.refreshForm([this.$refs.form]);
  }
}
